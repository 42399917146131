import { DARK, LIGHT } from '@/utils/themes'

type ThemeKey = 'system' | 'light' | 'dark'

const themes: Record<ThemeKey, () => typeof DARK | typeof LIGHT> = {
  system: () =>
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      ? themes.dark()
      : themes.light(),
  light: () => 'winter',
  dark: () => 'business',
}

function setTheme() {
  const keyFromStorage = getKeyFromStorage()
  const theme = themes[keyFromStorage || 'system']()
  document.querySelector('html')?.setAttribute('data-theme', theme)
}

function getKeyFromStorage(): ThemeKey {
  const keyFromStorage = localStorage.getItem('user-theme')
  return keyFromStorage === 'light' || keyFromStorage === 'dark' ? keyFromStorage : 'system'
}

export function useThemePlugin() {
  setTheme()

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    setTheme()
  })
}
