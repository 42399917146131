import { getLocalStorageItem, setLocalStorageItem } from '@/utils/local-storage'

import { PubSub } from './pub-sub'

const STORAGE_KEY_FAILED_REQUESTS = 'failedRequests'

const failedRequestCountTopic = new PubSub<number>()

interface FailedRequests {
  count: number
}

export function getFailedRequestsCount(): number {
  const failedRequests = getLocalStorageItem<FailedRequests>(STORAGE_KEY_FAILED_REQUESTS)
  return failedRequests?.count ?? 0
}

export function incrementFailedRequestsCount(): void {
  const current = getLocalStorageItem<FailedRequests>(STORAGE_KEY_FAILED_REQUESTS)
  const count = current ? current.count + 1 : 1
  const payload: FailedRequests = { count }
  setLocalStorageItem(STORAGE_KEY_FAILED_REQUESTS, payload)
  // local storage is not reactive - this makes it possible for components to react to changes
  failedRequestCountTopic.publish(count)
}

export function resetFailedRequestsCount(): void {
  window.localStorage.removeItem(STORAGE_KEY_FAILED_REQUESTS)
  failedRequestCountTopic.publish(0)
}

export function useAuthState(): PubSub<number> {
  return failedRequestCountTopic
}
